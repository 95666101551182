import './App.css';
import {
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import Main from './pages/main/main'
import Report from './pages/report/report'
import ExaminerReport from './pages/report/examiner'
import Protocol from './pages/report/protocol'
import ExamDetail from './pages/examDetail/examDetail'
import CertManger from './pages/certManger/certManger'


import NewsList from './pages/newsList/newsList'
import NewsDetail from './pages/newsList/newsDetail'
import Institutions from './pages/report/institutions'
import MyStudent from './pages/my/myStudent/myStudent'
import MyExamList from './pages/my/myExamList/myExamList'
import PublishExam from './admin/publishExam/publishExam'
import AddExam from './admin/publishExam/addExam/addExam'
import AddNews from './admin/publishExam/addNews/addNews'
import AddNewsInner from './admin/publishExam/addNews/addNewsInner'
import AddCultivate from './admin/publishExam/addCultivate/addCultivate'
import ExaminerStore from './admin/examinerStore/examinerStore'
import AdminStation from './admin/station/station'
import EnrollManage from './admin/enrollManage/enrollManage'
import QualityControl from './admin/qualityControl/qualityControl'
import AuthRole from './admin/authRole/authRole'
import MyInvigilating from './pages/my/myInvigilating/myInvigilating'
import AdminApplyExam from './admin/adminApplyExam/adminApplyExam';
import AdminAddScore from './admin/adminAddScore/adminAddScore';
import QueryData from './admin/queryData/queryData'
import ExamInvited from './pages/examInvited/examInvited'
import ExamInvitedList from './pages/examInvited/examInvitedList/examInvitedList'
import AddSchool from './admin/school/addSchool'

import { useEffect, useState } from 'react';
import Request from './unit/request';
import Cookies from 'js-cookie';
import { useLoginStore } from './store';
import { appid } from './unit/config';
import LoadingMask from './components/loadingMask/loadingMask';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCityData } from './store';
import { SpinLoading, Toast } from 'antd-mobile';

import tcb from "@cloudbase/js-sdk";

function App() {
	let errorNum = 0
	const { pathname } = useLocation()
	const initWx = () => {
		const url = window.location.href.split('#')[0]
		Request({
			method: 'post',
			url: '/weixin/wxconfig',
			data: {
				url
			}
		}).then(res => {
			window.wx.config({
				debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
				appId: appid, // 必填，公众号的唯一标识
				timestamp: res.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.noncestr, // 必填，生成签名的随机串
				signature: res.data.signature,// 必填，签名
				jsApiList: ["uploadImage", "chooseImage", "openLocation", "getLocation", "chooseWXPay"] // 必填，需要使用的 JS 接口列表
			});
			window.wx.ready(() => {
				console.log('ready-success')
			})

			window.wx.error((e) => {
				errorNum++
				// initWx()
				console.log('error' + errorNum)
				console.log('error' + JSON.stringify(e))
			})
		})
	}

	const navigate = useNavigate()
	const { outLogin, GetUserInfo } = useLoginStore((state) => state);
	const { getCityData } = useCityData((state) => state);
	const [isStatus, setIstatus] = useState(false)
	useEffect(() => {
		setTimeout(() => {
			initWx(pathname)
		}, 300)
		if (!Cookies.get('requestToken')) {
			outLogin()
		}


		setTimeout(() => {
			navigator("/main/my");
			Toast.show("登陆失败请重试！");
			setIstatus(true)
		}, 6000)

		GetUserInfo().then(res => {
			if (sessionStorage.toLoginPage) {
				navigate(sessionStorage.toLoginPage)
				sessionStorage.removeItem('toLoginPage')
			}
			setIstatus(true)
		}).catch((err) => {

			const app = tcb.init({
				env: "bjsports-3gjwsnfg45ed42fd"
			});
			var db = app.database();
			db.collection("books")
				.add({
					// _id: 'todo-identifiant-aleatoire', // 可选自定义 _id，在此处场景下用数据库自动分配的就可以了
					category: err,
					timestamp: new Date()
				})
				.finally(() => {
					console.log("添加成功");
					navigator("/main/my");

					setIstatus(true)
				})



			Toast.show("登陆失败请重试！");

		})
		getCityData()
	}, [pathname])

	return isStatus ? (
		<div className="App">
			<Routes>
				<Route path="*" element={<Navigate to="/main/index" />}></Route>
				<Route path='/main/*' element={<Main />}></Route>
				<Route path="/report" element={<Report />}></Route>
				<Route path="/examinerReport" element={<ExaminerReport />}></Route>
				<Route path="/institutions" element={<Institutions />}></Route>
				<Route path="/examDetail" element={<ExamDetail />}></Route>
				<Route path="/CertManger" element={<CertManger />}></Route>
				<Route path="/protocol" element={<Protocol />}></Route>
				<Route path="/newsList" element={<NewsList />}></Route>
				<Route path="/newsDetail" element={<NewsDetail />}></Route>
				<Route path="/myStudent" element={<MyStudent />}></Route>
				<Route path="/publishExam" element={<PublishExam />}></Route>
				<Route path="/adminExam" element={<PublishExam />}></Route>
				<Route path="/addExam" element={<AddExam />}></Route>
				<Route path="/addNews" element={<AddNews />}></Route>
				<Route path="/addNewsInner" element={<AddNewsInner />}></Route>
				<Route path="/myExamList" element={<MyExamList />}></Route>
				<Route path='/addCultivate' element={<AddCultivate />}></Route>
				<Route path='/examinerStore' element={<ExaminerStore />}></Route>
				<Route path='/adminStation' element={<AdminStation />}></Route>
				<Route path='/enrollManage' element={<EnrollManage />}></Route>
				<Route path='/qualityControl' element={<QualityControl />}></Route>
				<Route path='/authRole' element={<AuthRole />}></Route>
				<Route path='/myInvigilating' element={<MyInvigilating />}></Route>
				<Route path='/adminApplyExam' element={<AdminApplyExam />}></Route>
				<Route path='/adminAddScore' element={<AdminAddScore />}></Route>
				<Route path='/queryData' element={<QueryData />}></Route>
				<Route path='/examInvited' element={<ExamInvited />}></Route>
				<Route path='/examInvitedList' element={<ExamInvitedList />}></Route>
				<Route path='/addSchool' element={<AddSchool />}></Route>

			</Routes>
			<LoadingMask />
		</div>
	) : (
		<div className='loadingPage'>
			<SpinLoading color='primary' />
			<p>页面加载中</p>
		</div>
	);
}

export default App;
